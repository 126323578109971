/* Default Values (for use in SCSS) */
$primary-color-1: var(--primary-color-1);
$primary-color-2: var(--primary-color-2);
$secondary-color-1: var(--secondary-color-1);
$secondary-color-2: var(--secondary-color-2);
$tertiary-color-1: var(--tertiary-color-1);
$tertiary-color-2: var(--tertiary-color-2);
$quaternary-color-1: var(--quaternary-color-1);
$quaternary-color-2: var(--quaternary-color-2);
$quinary-color-1: var(--quinary-color-1);
$quinary-color-2: var(--quinary-color-2);

$primaryfont-color-1: var(--primaryfont-color-1);
$primaryfont-color-2: var(--primaryfont-color-2);
$secondaryfont-color-1: var(--secondaryfont-color-1);
$secondaryfont-color-2: var(--secondaryfont-color-2);
$black: var(--black);
$white: var(--white);

/* CMS Values / Default Values (overriden by strapi) */
:root {
  --primary-color-1: #add8e6;
  --primary-color-2: #72add4;
  --secondary-color-1: #cfe6ad;
  --secondary-color-2: #add472;
  --tertiary-color-1: #2b3777;
  --tertiary-color-2: #59618f;
  --quaternary-color-1: #445032;
  --quaternary-color-2: #7d8a6a;
  --quinary-color-1: #64502f;
  --quinary-color-2: #7a7469;
  --cms-green: #379683;
  --cms-lightbrown: #B1A296;
  --cms-darkbrown: #5D5C61;
  --cms-lightblue: #198fd9;
  --cms-darkblue: #1a85c7;
  --primaryfont-color-1: #000000;
  --primaryfont-color-2: #cccccc;
  --secondaryfont-color-1: #2b52a6;
  --secondaryfont-color-2: #3d7aff;
  --black: #000000;
  --white: #ffffff;

  /* Bootstrap */
  --bs-primary: var(--cms-green);
  --bs-secondary: var(--cms-darkbrown);
}


/* Breakpoints */
$tablet-breakpoint: 768px; /* above: Tablet, below: Mobile */
$desktop-breakpoint: 1200px; /* above: Desktop, below: Tablet */

/* Bootstrap Breakpoints (strapi breakpoints need special treatment) */
$zero: 0px;
$congigurable-breakpoint-mobile: 768px; // do not change this value 768px in this file
$congigurable-breakpoint-desktop: 1200px; // do not change this value 1200px in this file

$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1366px;
$xxl: 1921px;

// $font-light: 300;
// $font-semi-bold: 600;
// $font-bold: 700;
// $font-regular: 400;
// $default-text-color: #252b37;
// $color-dark-1: #000;

.CUSTOM-CSS {
  background-color: blue;
}